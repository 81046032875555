<template>
    <!-- Some release -->
    <v-row class="d-flex flex-row py-4 accent some-reeases" align="center" justify="center">
        <v-col cols="10" align="center" justify="center">
            <h1 class="hr-style white--text mx-autoyyy">Key figures
                <hr class="white" />
            </h1>
        </v-col>
        <v-col cols="12" sm="6" md="6" class="d-flex flex-row mx-auto  justify-center">
            <div class="releases mx-8">
                <v-icon class="white--text">{{ icons.mdiEyeOutline }}</v-icon>
                <h2>+ 12356</h2>
                <h5>Companies</h5>
            </div>
            <!-- </v-col>
        <v-col cols=""> -->
            <div class="releases mx-8">
                <v-icon class="white--text">{{ icons.mdiEyeOutline }}</v-icon>
                <h2>+ 12356</h2>
                <h5>Companies</h5>
            </div>
        <!-- </v-col>
        <v-col cols="12" sm="6" md="6" class="d-flex flex-row mx-auto  justify-center"> -->
            <div class="releases mx-8">
                <v-icon class="white--text">{{ icons.mdiEyeOutline }}</v-icon>
                <h2>+ 12356</h2>
                <h5>Companies</h5>
            </div>
            <!-- </v-col>
        <v-col cols=""> -->
            <div class="releases mx-8">
                <v-icon class="white--text">{{ icons.mdiEyeOutline }}</v-icon>
                <h2>+ 12356</h2>
                <h5>Companies</h5>
            </div>
        </v-col>
    </v-row>
</template>

<script>
import { mdiEyeOutline } from '@mdi/js'

export default {

    setup(props) {
        const icons = { mdiEyeOutline }

        return { icons }
    }
}
</script>


<style lang="scss">
//@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

//@import '~@/styles/main-style.scss';
@import '~@/styles/utils-style.scss';

.some-reeases {
    text-align: center;

    .releases {
        color: var(--v-primaryAccent-base);
    }
}
</style>