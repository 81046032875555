<template>
    <div>
        <!-- <v-btn text oulined class="subprimary">d </v-btn> -->
        <v-card outlined class="pa-1 rounded-lg">
            <div class="d-flex flex-row">
                <v-card-text class="d-flex flex-column justify-space-between align-center py-0 px-0">
                    <v-row align="center" class="py-3 px-2 w-full">
                        <v-icon class="mr-1">
                            {{ icons.mdiHeart }}
                        </v-icon>
                        <span class="subheading mr-2">256</span>
                        <!-- <span class="mr-1">·</span> -->
                        <v-spacer></v-spacer>
                        <v-btn small text color="secondary" class="ubsecondary" @click="joinToConnect()">
                            <v-icon small class="mr-1">
                                {{ icons.mdiSetCenter }}
                            </v-icon>
                            <span class="text-capitalize">Join</span>
                        </v-btn>
                    </v-row>
                    <v-avatar size="80" color="white" class="">
                        <v-img src="@/assets/images/avatars/profil.svg"></v-img>
                    </v-avatar>
                    <v-card-title>
                        {{ user.firstname }} {{ user.laststname }}
                    </v-card-title>
                    <v-card-subtitle>{{ user.firstname }} </v-card-subtitle>
                    <!-- <v-spacer></v-spacer> -->
                    <v-card-actions class="pb-0">
                        <v-btn text block small color="secondary" class="text-caption" @click="goToreferenceLink()">
                            View profile
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
                <div class="subsecondary rounded-lg px-0">
                    <v-card-text>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>Domain</v-list-item-title>
                                <v-list-item-subtitle>{{ user.adress }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <!-- <v-list-item-action>
                                <v-list-item-action-text>Spacekola</v-list-item-action-text>
                                <v-icon color="grey lighten-1">
                                    {{ icons.mdiSetCenter }}
                                </v-icon>
                            </v-list-item-action> -->
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    {{ icons.mdiEmailOutline }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Email adress</v-list-item-title>
                                <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    {{ icons.mdiPhoneOutline }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>Phone number</v-list-item-title>
                                <v-list-item-subtitle>{{ user.phonenumber }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-text>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { mdiEmailOutline, mdiPhoneOutline, mdiCartPlus, mdiHelpCircleOutline, mdiSetCenter, mdiHeart } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        user: {
            type: Object,
            default: {}
        },
        link: {
            type: Object,
            default: undefined
        },
    },
    methods: {
        goToreferenceLink(value) {
            this.$router.push({ name: 'user.views', params: { ids: this.user.id, f_id: this.user.firstname } })
        },

        onReadyAndSendNewColaboration() {
            var formColaborator = {
                "colaborator": {
                    "user.id": this.$store.getters.getUser.id,
                    "recipientuser": this.$store.getters.getColaboration.recipient,
                }
            }
            // console.log(formColaborator)
            Drequest.api("colaboration.send")
                .data(formColaborator)
                .raw((response) => {
                    switch (response.success) {
                        case true:
                            this.$fire({
                                type: "success",
                                text: "We have notified..!" + this.$store.getters.getColaboration.name,
                                timer: 3000
                            })
                            // this.$router.push({ name: 'professional-home' });
                            break;
                        default:
                            this.$fire({
                                type: "Error",
                                text: "Error occured, please retry later..!",
                                timer: 3000
                            })
                            this.loading = false
                            break;
                    }
                })
                .catch((err) => {
                    this.$fire({
                        type: "error",
                        text: "Connexion error..!"
                    })
                    this.loading = false;
                });
        },
        joinToConnect() {
            this.$store.commit("setColaboration", {
                initialized: true,
                owner: '',
                name: this.user.firstname,
                recipient: this.user.id,
                // recipient: this.$route.params.ids,
            });

            if (!this.$store.getters.isLoggedIn) {
                this.$fire({
                    text: "You have to logIn..!"
                })
                this.$router.push({ name: 'login' })
            }
            else {
                this.onReadyAndSendNewColaboration()
            }
        },
    },
    setup(props) {
        const icons = { mdiEmailOutline, mdiPhoneOutline, mdiCartPlus, mdiHelpCircleOutline, mdiSetCenter, mdiHeart }
        return {
            icons,
        }
    },
}
</script>

<style lang="scss" scoped>
.b {
    border: solid 1px #000;
}
</style>