<template>
    <div>
        <v-card outlined height="100%" class="d-flex flex-column align-center py-6 mr-3">
            <!-- <v-card outlined height="100%" class="d-flex flex-column align-center py-6 px-6 mr-3" v-if="user"> -->
            <v-avatar size="75" color="white" class="">
                <v-img src="@/assets/images/avatars/profil.svg"></v-img>
            </v-avatar>
            <h4 class="text-center hr-style red--text">
                {{ user.firstname }} {{ user.laststname }}
            </h4>
            <div class="d-flex flex-column justify-center align-center">
                <v-icon size="1em" color="primary">
                    {{ icons.mdiHelpCircleOutline }}
                </v-icon>
                <span class="ms-1 primary--text text-caption text-center">
                    {{ user.adress + " " + user.city }}
                </span>
            </div>
            <div class="d-flex flex-row">
            </div>
            <div class="d-flex flex-row">
                <!-- <v-btn rounded small color="primary" outlined class="mt-3  px-4 text-caption mr-2"
                    @click="goToreferenceLink()">
                    Details</v-btn> -->
                <v-btn rounded small color="primary" class="mt-3 px-4 ml-2 text-caption" @click="goToreferenceLink()">
                    Details</v-btn>
            </div>
        </v-card>
    </div>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import { mdiCartPlus, mdiShareVariantOutline, mdiHelpCircleOutline } from '@mdi/js'
var md5 = require("md5")

export default {
    props: {
        user: {
            type: Object,
            default: () => { },
        },
        link: {
            type: Object,
            default: undefined
        },
    },
    methods: {
        goToreferenceLink() {
            this.$router.push({ name: 'user.views', params: { ids: this.user.id, f_id: this.user.firstname } })
        }
    },
    setup(props) {
        const icons = { mdiCartPlus, mdiShareVariantOutline, mdiHelpCircleOutline }

        return {
            icons
        }
    },
}
</script>

<style lang="scss">
</style>