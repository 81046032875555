<template>
    <div class="">
        <header-page :header="headers"></header-page>
        <v-container>
            <v-row>
                <v-col>
                    <header-search-page :header="headersSearch" :loader="loader.loadSearchResult"
                        :result="searchDetails" @close="closeSearch" @action="searchActions">
                    </header-search-page>
                </v-col>
            </v-row>

            <v-row class="w-full">
                <!-- <v-col v-for="(item, i) in searching.data" :key="i"> -->
                <v-col cols="6" class="my-auto mx-auto text-center" v-if="loader.loadSearchResult">
                    <!-- <p>Loading professionals</p> -->
                    <v-progress-circular :size="70" :width="7" color="secondary" indeterminate></v-progress-circular>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, i) in searchUser" :key="i" v-else>
                    <user-sm :user="item" class=""></user-sm>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="d-flex flex-column">
                    <h1 class="secondary--text my-0 pb-2" width="auto">
                        Network with Professionnals
                        <!-- <hr class="secondary" /> -->
                    </h1>
                    <p>View most recommended Professionnals</p>
                    <!-- <v-avatar size="2em" >
                        <v-icon color="white" class="secondary">{{ icons.mdiArrowDecisionOutline }}
                        </v-icon>
                    </v-avatar> -->
                </v-col>
            </v-row>
        </v-container>

        <v-container>
            <v-row v-if="loader.loadrecomduser === true">
                <v-col cols="6" sm="4" md="3" v-for="index in 4" :key="index">
                    <v-skeleton-loader v-bind="attrs" type="card-avatar, list-item-three-line, actions">
                    </v-skeleton-loader>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" sm="4" md="3" lg="3" v-for="(item, index) in userList" :key="index">
                    <user-sm :user="item" class=""></user-sm>
                </v-col>
                <v-col cols="12">
                    <v-btn class="mx-auto text-center" color="primary" :disabled="loadmore.disblebtn"
                        @click="loadmoreuser()">Load more</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card outlined class="mb-2 rounded-lg">
                        <div class="pa-4">
                        </div>
                        <v-card-text class="">
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <!-- <user-slider :items="userList"></user-slider> -->

        <!-- Some releases app -->
        <some-releases></some-releases>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountGroup, mdiDomain, mdiMapMarkerOutline, mdiMagnify, mdiHelpCircleOutline, mdiArrowLeftThinCircleOutline, mdiArrowRightThinCircleOutline } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { ref } from '@vue/composition-api'
import HeaderPage from '@/views/pages/components/HeaderPage.vue'
import UserCard from '@/views/pages/users/components/UserCard.vue'
import SearchUsers from './components/SearchUsers.vue'
import UserSlider from '@/views/pages/users/components/UserSlider.vue'
import UserSm from '@/views/pages/users/components/UserSm.vue'
import HeaderSearchPage from '@/views/components/HeaderSearchPage.vue'
import User from '@/views/pages/users/components/User.vue'
import SomeReleases from './components/SomeReleases.vue'

export default {
    components: {
        UserCard,
        SearchUsers,
        HeaderPage,
        UserSlider,
        UserSm,
        User,
        HeaderSearchPage,
        SomeReleases
    },
    data() {
        return {
            no_resultitem: 0,
            searchDetails: '',
            loader: {
                loadSearchResult: false,
                loadrecomduser: true,
                loaderdata: false,
                isImgLoaded: false,
            },
            searchUser: [],
            userList: [],
            search: '',

            headersSearch: {
                title: "Search professionals",
                subtitle: "Search and connect with professionnals",
                icon: '',
                search: {
                    offer: {
                        text: 'Search by offer',
                        icon: mdiMagnify
                    },
                    location: {
                        text: 'Search by location',
                        icon: mdiMapMarkerOutline
                    },
                    domain: {
                        text: 'Search by domain',
                        icon: mdiDomain
                    },
                    company: {
                        text: 'Search by company',
                        icon: mdiDomain
                    },
                }
            },
            headers: {
                titlepage: 'Featured with professionnals.',
                icon: this.icons.mdiAccountGroup
            },
            attrs: {
                class: 'mb-6',
                boilerplate: true,
                elevation: 2,
            },
            // Current page
            loadmore: {
                disblebtn: false,
                currentpage: 1,
                perpage: 4
            }
        }
    },
    beforeMount() {
        this.loader.loadrecomduser = true
        this.loadProfessionalsUser(this.loadmore.currentpage, this.loadmore.perpage)
    },

    computed: {
        searching() {
            if (!this.search || this.search.trim().length < 2) return { isEmpty: true, data: [] }
            else {
                return {
                    isEmpty: false, data: this.userList.filter(item => {
                        const text = item.firstname.toLowerCase()
                        return text.indexOf(this.search.toLowerCase().trim()) > -1
                    })
                }
            }
        },
    },
    methods: {
        loadProfessionalsUser(nextpage, perpage) {
            Drequest.api(`lazyloading.user?dfilters=on&id:neq=${this.$store.getters.getUser.id}&next=${nextpage}&per_page=${perpage}`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.userList.push({
                                id: value.id,
                                firstname: value.firstname,
                                lastname: value.lastname,
                                username: value.username,
                                logo: value.logo,
                                country: value.country
                            })
                        })
                        this.loader.loadrecomduser = false
                        if (response.nb_element === this.userList.length) {
                            this.loadmore.disblebtn = true
                        }
                        else {
                            this.loadmore.disblebtn = false
                        }
                    } else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 3000
                        })
                        this.loader.loadrecomduser = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.loader.loadrecomduser = false;
                })
        },
        loadmoreuser() {
            this.loadmore.currentpage = this.loadmore.currentpage + 1
            this.loadProfessionalsUser(this.loadmore.currentpage, this.loadmore.perpage)
        },
        loadImage() {
            this.loader.isImgLoaded = true;
        },

        closeSearch() {
            this.loader.loaderdata = true
            this.searchUser = []
        },
        searchActions(data) {
            this.loader.loadSearchResult = true
            this.loader.loaderdata = true
            const mainInputText = data.title.isEmpty === false ? data.title.value : ''
            const locationInputText = data.location.isEmpty === false ? data.location.value : ''
            const domainInputtext = data.domain.isEmpty === false ? data.domain.value : ''
            const companyInputtext = data.company.isEmpty === false ? data.company.value : ''

            this.searchUser = []
            Drequest.api(`user.search?name=${mainInputText}&location=${locationInputText}`)
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((element, index) => {
                            this.searchUser.push(element)
                        });
                        this.searchDetails = response.detail
                        this.no_resultitem = response.nb_element
                        this.loader.loadSearchResult = false
                        this.loader.loaderdata = false
                    }
                    else {
                        this.loader.loadSearchResult = false
                        this.searchDetails = response.detail
                        this.loader.loaderdata = false
                        this.no_resultitem = 0
                    }
                })
                .catch((err) => {
                    this.loader.loadSearchResult = false
                    this.loader.loaderdata = false
                    this.no_resultitem = 0
                });
        },
    },
    setup() {
        const icons = {
            mdiAccountGroup, mdiDomain, mdiMapMarkerOutline, mdiHelpCircleOutline, mdiMagnify, mdiArrowLeftThinCircleOutline, mdiArrowRightThinCircleOutline
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
//@import '~@/assets/css/style.scss';

.card {
    border: 1px solid rgba(0, 0, 0, .125) !important;
}
</style>
