<template>
    <div class="">
        <v-container>
            <v-row justify="center" class="mb-4">
                <v-col cols="12" class="header-profes-page">
                    <v-card class="rounded-lg" width="100%">
                        <v-img height="10em" src="@/assets/images/pages/person-client.png" cover>
                            <div class="subheader-profes">
                                <v-card-text class="px-0">
                                    -- <span class="accent--text">Searh and connect with professionnals</span>
                                </v-card-text>
                                <div color="accent" class="title">Search professionnals</div>
                            </div>
                        </v-img>
                    </v-card>
                    <div class="absolute-search-box">
                        <v-card class="pa-3 rounded-lg">
                            <div class="d-flex flex-row justify-center">
                                <v-text-field v-model="search" :prepend-inner-icon="icons.mdiMagnify" dense outlined
                                    hide-details clearable></v-text-field>
                                <v-text-field dense outlined hide-details clearable class="ml-3"></v-text-field>
                                <v-btn text color="secondary" class="subsecondary rounded-lg ml-3">
                                    <v-icon color="secondary">
                                        {{ icons.mdiMagnify }}</v-icon>
                                    Search
                                </v-btn>
                            </div>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card class="mt-4" flat v-if="searching.isEmpty === false">
                        <v-container>
                            <v-row>
                                <v-col v-if="searching.data.length <= 0" cols="12"
                                    class="text-center justify-center align-center">
                                    <span>No professionnal found..!</span>
                                </v-col>
                                <v-col v-else v-for="(item, i) in searching.data" :key="i">
                                    <!-- <company :company="item"></company> -->
                                    <user :user="item"></user>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline, mdiMagnify, mdiHelpCircleOutline, mdiArrowLeftThinCircleOutline, mdiArrowRightThinCircleOutline } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { ref } from '@vue/composition-api'
import UserCard from '@/views/pages/users/components/UserCard.vue'
import User from '@/views/pages/users/components/User.vue'

export default {
    components: {
        UserCard,
        User
    },
    data() {
        return {
            isImgLoaded: false,
            loadingData: true,
            userList: [],
            search: '',
        }
    },

    computed: {
        searching() {
            // console.log(this.search)
            if (!this.search || this.search.trim().length < 1) return { isEmpty: true, data: [] }
            else {
                return {
                    isEmpty: false, data: this.userList.filter(item => {
                        const text = item.firstname.toLowerCase()
                        return text.indexOf(this.search.toLowerCase().trim()) > -1
                    })
                }
            }
        },
    },
    methods: {
        loadImage() {
            this.isImgLoaded = true;
        },
    },
    mounted() {
        this.loadingData = true
        Drequest.api("lazyloading.user")
            .get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((value, index) => {
                        this.userList.push({
                            id: value.id,
                            email: value.email,
                            firstname: value.firstname,
                            lastname: value.lastname,
                            phonenumber: value.phonenumber,
                            username: value.username,
                            adress: value.adress,
                            city: value.city,
                            logo: value.logo
                        })
                    })
                    // console.log(this.userList)
                    this.loadingData = false
                }
            })
            .catch((err) => {
                console.log("err")
                this.loadingData = false;
            })
    },
    setup() {
        const icons = {
            mdiHelpCircleOutline, mdiMagnify, mdiArrowLeftThinCircleOutline, mdiArrowRightThinCircleOutline
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
//@import '~@/assets/css/style.scss';

.card {
    border: 1px solid rgba(0, 0, 0, .125) !important;
}

.header-profes-page {
    position: relative;
    margin-bottom: 2em;
}

/*.header-profes-page .theme--light.v-sheet h1 {
    color: var(--v-accent-base) !important;
    size: 20pt;
    font-weight: 400;
}*/

.header-profes-page .title {
    color: var(--v-accent-base) !important;
    size: 35pt;
    font-weight: bold;
}

.absolute-search-box,
.subheader-profes {
    width: 86%;
}

.subheader-profes {
    margin-left: calc(7% - 0.8em);
    margin-right: 7%;
    margin-top: 1.5em;
}

.absolute-search-box {
    position: absolute;
    left: 7%;
    right: 7%;
    top: calc(8.5em);
}

.avatar-center {
    top: -3rem;
    left: 3rem;
    border: 3px solid white;
    position: absolute;
}
</style>
