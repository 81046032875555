<template>
    <div class="slide-similar mb-4">
        <hooper :infiniteScroll="true" :itemsToShow="showsNbItems" :progress="true" :autoPlay="true" :centerMode="true"
            :playSpeed="3000" ref="carousel"  class="">
            <slide v-for="(item, index) in items" :key="index">
                <user-sm :user="item" class="mr-12"></user-sm>
            </slide>
            <hooper-navigation slot="hooper-addons">
                <v-btn icon slot="hooper-prev" class="secondary ml-6">
                    <v-icon color="white">
                        {{ icons.mdiChevronLeft }}
                    </v-icon>
                </v-btn>
                <v-btn icon slot="hooper-next" class="secondary mr-6">
                    <v-icon color="white">
                        {{ icons.mdiChevronRight }}
                    </v-icon>
                </v-btn>
            </hooper-navigation>
        </hooper>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref } from '@vue/composition-api'
import { mdiEyeOutline, mdiEyeOffOutline, mdiHelpCircleOutline, mdiChevronLeft, mdiChevronRight, mdiArrowDecisionOutline } from '@mdi/js'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';
import UserSm from '@/views/pages/users/components/UserSm.vue'
import 'hooper/dist/hooper.css';

export default {
    props: {
        items: {
            type: Array(),
            default: () => []
        },
        xl: {
            type: Number(),
            default: 6
        },
        lg: {
            type: Number(),
            default: 5
        },
        md: {
            type: Number(),
            default: 4
        },
    },
    components: {
        Hooper, Slide,
        HooperNavigation,
        UserSm
    },
    data() {
        return {
            isImgLoaded: false,
        }
    },

    methods: {
        loadImage() {
            this.isImgLoaded = true;
        },
    },
    computed: {
        showsNbItems() {
            if (this.$vuetify.breakpoint.xl) {
                if(this.xl){
                    return this.xl
                }
                else{
                    return 6
                }
            }
            if (this.$vuetify.breakpoint.lg) {
                if(this.lg){
                    return this.lg
                }
                else{
                    return 5
                }
            }
            if (this.$vuetify.breakpoint.md) {
                if(this.md){
                    return this.md
                }
                else{
                    return 4
                }
            }
            if (this.$vuetify.breakpoint.sm) {
                return 2;
            }
            return 1;
        }
    },
    setup() {
        const icons = {
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiHelpCircleOutline,
            mdiEyeOutline,
            mdiEyeOffOutline,
            mdiHelpCircleOutline,
            mdiChevronLeft, mdiChevronRight, mdiArrowDecisionOutline
        }
        return {
            icons
        }
    },
}
</script>

<style lang="scss">
@import '~@/assets/css/style.scss';
</style>
